import {EsquireClient} from "@/types/clients/esquire";
import OptionData, {IOptionData} from "@/types/clients/esquire/data/general/contract-data/option";


export default class GetFlatOptions {
  constructor(private client: EsquireClient) {}

  public async send(flatTypes: Array<string>, nights: number): Promise<OptionData[]> {

    const url = await this.client.fetchUrlFromLocalStorage("get-flat-options");

    const data = {
      flat_types: flatTypes,
      nights: nights
    };

    const response = await this.client.fetch(
      () => new URL(url), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }
    );

    if (!response.ok) {
      await this.client.logOutIfForbidden(response.status);
      throw new Error("Failed to fetch flat options");
    }

    const result =  await response.json();
    return result.map((optionData: IOptionData) => new OptionData(optionData));


  }
}
