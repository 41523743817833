<template>
  <v-app-bar color="primary">
    <v-app-bar-title>Be-Housing Guest App</v-app-bar-title>

    <template v-slot:append>
      <!-- Change Language Section -->
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-web" v-bind="props"></v-btn>
        </template>
        <v-list>
          <v-list-item v-for="language in languages" :key="language.code" @click="changeLanguage(language.code)">
            <v-list-item-title>{{ language.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- Log Out Section -->
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title @click="logOut">
              <v-btn append-icon="mdi-logout" style="width: 100%">
                {{ GlobalService.translator().trans("general.menu.log_out") }}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script lang="ts" setup>

import GlobalService from "@/services/global-service";

const languages = [
  { code: "en", name: "English" },
  { code: "nl", name: "Nederlands" },
  { code: "fr", name: "Français" }
];

function logOut() {
  const lang = localStorage.getItem("language") ?? "en";
  localStorage.clear();
  GlobalService.translator().setLanguage(lang);
  GlobalService.router().routeTo("auth");
}
function changeLanguage(language: string) {
  GlobalService.translator().setLanguage(language);
  GlobalService.router().refreshCurrentPage();
}

</script>

<style scoped>

</style>
