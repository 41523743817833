import esquire from "@/types/clients/esquire";
import GetCountries from "@/types/clients/esquire/requests/general/get-countries";
import Country from "@/types/clients/esquire/data/general/country";

class CountryApi {

  async index(): Promise<Country[]> {
    const request = new GetCountries(esquire);
    return await request.send();

  }
}

export default new CountryApi();
