import {EsquireClient} from "@/types/clients/esquire";
import PutOfferResponse from "@/types/clients/esquire/responses/put-offer-response";
import PutResponse from "@/types/clients/esquire/responses/put-response";

export default class PutOfferRequest {
  constructor(private client: EsquireClient) {
  }

  public async send(offerData: PutOfferResponse): Promise<PutResponse> {
    const url = await this.client.fetchUrlFromLocalStorage("put-offer");

    const response = await this.client.fetch(
      () => new URL(url), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: offerData.toJsonString()
      });

    await this.client.logOutIfForbidden(response.status);
    const responseData = await response.json(); // Assuming the backend returns a JSON response

    if (!response.ok) {
      // If response is not ok (e.g., status 400 or 500)
      return new PutResponse(responseData.message, response.status, responseData.identifier);
    }

    // If successful, return the custom message and identifier
    return new PutResponse(responseData.message, response.status, responseData.identifier);

  }
}
