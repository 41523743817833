
export type FlatDetailData = {
  id: number;
  type: string;
  language: string;
  name: string;
  info: string;
};

export default class FlatDetail {
  public id: number;
  public type: string;
  public language: string;
  public name: string;
  public info: string;

  constructor(data: FlatDetailData) {
    this.id = data.id;
    this.type = data.type;
    this.language = data.language;
    this.name = data.name;
    this.info = data.info;
  }

  toJson(): Record<string, unknown> {
    return {
      id: this.id,
      type: this.type,
      language: this.language,
      name: this.name,
      info: this.info
    };
  }

}
