import OptionApi from "@/services/esquire/option-api";
import OfferApi from "@/services/esquire/offer-api";
import ContractApi from "@/services/esquire/contract-api";
import CountryApi from "@/services/esquire/country-api";

class EsquireService {

  contracts() {
    return ContractApi;
  }
  countries() {
    return CountryApi;
  }
  offers() {
    return OfferApi;
  }

  options() {
    return OptionApi;
  }
}

export default new EsquireService();
