export interface ICountry {
    name: string,
    code: string,
    language: string;
}

export default class Country {
    public name: string;
    public code: string;
    public language: string;

    constructor(data: ICountry) {
        this.name = data.name;
        this.code = data.code;
        this.language = data.language;
    }
}
