import {EsquireClient} from "@/types/clients/esquire";
import PutResponse from "@/types/clients/esquire/responses/put-response";
import PostContractNotePayload from "@/types/clients/esquire/payloads/post-contract-note-payload";

export default class PostContractNoteRequest {
  constructor(private client: EsquireClient) {}

  public async send(contractNoteData: PostContractNotePayload): Promise<PutResponse> {
    const url = await this.client.fetchUrlFromLocalStorage("post-contract-note");

    const response = await this.client.fetch(
      () => new URL(url), {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(contractNoteData.toJsonObject())
      });

    await this.client.logOutIfForbidden(response.status);
    const responseData = await response.json();

    if (!response.ok) {
      return new PutResponse(responseData.message, response.status, responseData.identifier);
    }

    return new PutResponse(responseData.message, response.status, responseData.identifier);

  }
}
