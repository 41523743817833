export default {
  contract_id: "Contract ID",
  checkin: {
    fill_in: "Please fill in the guest details",
    private: "Private",
    business: "Business",
    company_name: "Company name",
    gender: "Gender",
    first_name: "First name",
    last_name: "Last name",
    street: "Street",
    zip: "Zip code",
    phone_number: "Phone number",
    email: "Email",
    country: "Country",
    city: "City",
    continue: "Continue"
  },
  options: {
    options: "Options",
    select_additional: "Select additional options"
  },
  overview: {
    overview: "Overview",
    name: "Name",
    address: "Address",
    phone: "Phone",
    email: "Email",

    invoice_lines: "Invoice lines",
    removed_options: "Removed options",
    added_options: "Newly added options",

    continue_pay_later: "Continue and pay later",
    contract: "Contract",
    guest_info: "Guest information",
    total_pay_today: "Total to pay today: ",
    confirm_and_pay: "Confirm & Pay now",
    terms_and_conditions: "Terms and conditions",
    accept_terms_and_conditions: "I accept the general terms and conditions"
  },
  passport: {
    passport_scanner: "Passport scanner",
    legal_1: "Due to legal requirements, we must scan your passport for verification. Please upload a clear picture of the side containing the Machine Readable Zone (MRZ).",
    legal_2: "It consists of two or three lines of numbers, letters, and symbols. This information is crucial for the authentication of your passport.",
    mrz_look_like: "What does an MRZ look like?",
    mrz_example: "MRZ Example",
    close: "Close",
    upload_passport: "Upload passport image",
    warning_overwrite: "Warning: uploading a new passport will overwrite your current passport.",
    rejected: "Your passport has been rejected.",
    reason: "Reason: ",
    overwrite_passport: "Overwrite passport",
    process_passport: "Process passport",
    passport_data: "Passport Data",
    gender: "Gender",
    nationality: "Nationality",
    first_name: "First Name",
    last_name: "Last Name",
    is_data_correct: "Is the passport data correct?",
    yes: "Yes",
    no: "No",
    password_approved: "Passport approved",
    awaiting_approval: "Awaiting manual passport check by sales team",
    failed_to_process: "Failed to process passport. Please make sure the passport is clearly visible.",
    passport_confirmation: "Passport Confirmation",
    picture_correct: "Picture is correct",
    correct_picture_wrong_data: "The picture you uploaded is correct but the data is read out wrong: ",
    sales_team_manual_lookup: "our sales team will manually inspect this picture and fill in the correct data.",
    upload_new_picture: "Upload new picture",
    remove_picture_upload_new: "The current picture will be removed and you will be able to upload a new passport picture.",
    enter_now: "I will enter my passport now",
    provide_at_checkin: "I will provide my passport during checkin",
    provide_at_checkin_during_hours: "I will provide my passport during reception hours between [checkin_time] and [checkout_time] at check-in."
  },
  key_pickup: {
    key_pickup_instructions: "Key Pickup Instructions",
    gate_code: "Gate code: ",
    vault_code: "Vault code: ",
    check_in: "You can check-in at our manned reception on [arrival_date] between [checkin_time] and [checkout_time]",
    late_arrival_notice: "Attention: Arriving after [checkin_day] or booked an early check-in?",
    follow_instructions: "Then follow the following instructions: ",
    access_entrance: "To access the entrance hall enter code ",
    entrance_description: "At the entrance hall you will see the safe were you will find the key to your room and some information.",
    code_safe: "The code for this safe is ",
    safe_instructions: "Turn the lock of the safe to the right to open the door/left to close again. ",
    inside_envelope_letter: "Inside you will find an envelope with letter ",
    personal_envelope: ", this is your personal envelope. ",
    inside_envelope: "Inside you will find an envelope with  ",
    your_name: "YOUR NAME ",
    key_document_inside: "Your key with room number and document are inside this envelope, along with the contact details. ",
    contact_residence: "In case of any difficulties you can contact the residence manager at the follow emergency number: ",
    complete_checkin_for_instructions: "Complete your checkin to gain access to your key pickup instructions.",
    instructions_on_arrival_day: "On the day of arrival you will find your check-in instructions here."
  }
}
