import {EsquireClient} from "@/types/clients/esquire";
import Country, { ICountry } from "@/types/clients/esquire/data/general/country";

export default class GetCountries {
  constructor(private client: EsquireClient) {}

  public async send(): Promise<Country[]> {
    const url = await this.client.fetchUrlFromLocalStorage("countries");

    const response = await this.client.fetch(
      () => new URL(url), {
        method: "GET"
      }
    );

    if (!response.ok) {
      await this.client.logOutIfForbidden(response.status);
      throw new Error("Failed to fetch offers");
    }

    const result =  await response.json();
    return result.map((countryData: ICountry) => new Country(countryData));


  }
}
