import SelectedFlat, {SelectedFlatData} from "@/types/clients/esquire/data/offer/selected-flat";
import OfferOption from "@/types/clients/esquire/data/offer/offer-option";
import FlatDetail, {FlatDetailData} from "@/types/clients/esquire/data/offer/flat-detail";
import Option, {OptionData} from "@/types/clients/esquire/data/general/option";
import translator from "@/types/services/Translator";

export type OverviewFlatData = {
  price: number;
  description: string;
  flat_type: string;
  price_per_night: string;
  selected_flats: SelectedFlatData[];
  flat_details: FlatDetailData[];
  option_translations: OptionData[];
};

export default class OverviewFlat {
  public price: number;
  public description: string;
  public flatType: string;
  public pricePerNight: string;
  public selectedFlats: SelectedFlat[];
  public flatDetails: FlatDetail[];
  public optionTranslations: Option[];

  constructor(data: OverviewFlatData) {
    this.price = data.price;
    this.description = data.description;
    this.flatType = data.flat_type;
    this.pricePerNight = data.price_per_night;
    this.selectedFlats = Array.isArray(data.selected_flats)
      ? data.selected_flats.map(flat => new SelectedFlat(flat))
      : [];
    this.flatDetails = Array.isArray(data.flat_details)
      ? data.flat_details.map(flatDetail => new FlatDetail(flatDetail))
      : [];
    this.optionTranslations = Array.isArray(data.option_translations)
      ? data.option_translations.map(optionTranslation => new Option(optionTranslation))
      : [];
  }

  toJson(): Record<string, unknown> {
    return {
      price: this.price,
      description: this.description,
      flat_type: this.flatType,
      price_per_night: this.pricePerNight,
      selected_flats: this.selectedFlats.map(flat => flat.toJson()),
      flat_details: this.flatDetails.map(flatDetail => flatDetail.toJson()),
      option_translations: this.optionTranslations.map(flatDetail => flatDetail.toJson())
    };
  }

  get flatNameInCurrentLanguage(): string{
    return this.getFlatDetail()?.name || this.description;
  }

  public getFlatDetail(): FlatDetail | undefined {
    const currentLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is set

    // Find the first FlatDetail that matches the current language
    return this.flatDetails.find((flatDetail: FlatDetail) => flatDetail.language === currentLanguage);
  }


  public priceIncludingOptions(): number {
    return this.selectedFlats.reduce((total, flat) => total + flat.price, 0);
  }

  public totalSumOfSelectedFlatsIncludingOptions(): number{
    return this.selectedFlats.reduce((total, selectedFlat) => {
      const basePrice = selectedFlat.priceApartment;
      const optionPrices = selectedFlat.offerOptions.reduce(
        (optionTotal, option) => optionTotal + option.totalPrice,
        0
      );
      return total + basePrice + optionPrices;
    }, 0);
  }

  public getSelectedFlatDetails(): { description: string; price: number; }[] {
    const language = localStorage.getItem("language") || "en";
    return this.selectedFlats.flatMap(selectedFlat => {
      const flatDetails = {
        description: translator.trans("admin.offer.apartment") + " (" + selectedFlat.amountOfPersons + " " + translator.trans("admin.offer.persons") + ")",
        price: selectedFlat.priceApartment
      };

      const options = selectedFlat.offerOptions.map((option: OfferOption) => {
        // Find the matching translation based on the current language
        const translation = this.optionTranslations.find(
          translation => translation.typeId === option.option.typeId && translation.language === language
        );

        return {
          description: translation?.name || option.name,
          price: option.totalPrice
        };
      });


      return [flatDetails, ...options];
    });
  }


  get isValid(): boolean {
    return this.selectedFlats.every(flat => flat.isValid);
  }
}
