import JsonResource from "@/types/interfaces/JsonResource";

export default class PostContractNotePayload implements JsonResource {
  public contractId: string;
  public noteType: string;
  public noteDescription: string|null;

  constructor(contractId: string, noteType: string, noteDescription:null|string = null) {
    this.contractId = contractId;
    this.noteType = noteType;
    this.noteDescription = noteDescription;
  }

  toJsonObject(): Record<string, unknown> {
    return {
      contract_id: this.contractId,
      note_type: this.noteType,
      note_description: this.noteDescription
    };
  }

  toJsonString(): string {
    return JSON.stringify(this.toJsonObject());
  }
}
