import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createBlock as _createBlock } from "vue"

import GlobalService from "@/services/global-service";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppToolbar',
  setup(__props) {


const languages = [
  { code: "en", name: "English" },
  { code: "nl", name: "Nederlands" },
  { code: "fr", name: "Français" }
];

function logOut() {
  const lang = localStorage.getItem("language") ?? "en";
  localStorage.clear();
  GlobalService.translator().setLanguage(lang);
  GlobalService.router().routeTo("auth");
}
function changeLanguage(language: string) {
  GlobalService.translator().setLanguage(language);
  GlobalService.router().refreshCurrentPage();
}


return (_ctx: any,_cache: any) => {
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, { color: "primary" }, {
    append: _withCtx(() => [
      _createVNode(_component_v_menu, null, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_v_btn, _mergeProps({ icon: "mdi-web" }, props), null, 16)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_list, null, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(languages, (language) => {
                return _createVNode(_component_v_list_item, {
                  key: language.code,
                  onClick: ($event: any) => (changeLanguage(language.code))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(language.name), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              }), 64))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_menu, null, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_v_btn, _mergeProps({ icon: "mdi-dots-vertical" }, props), null, 16)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, { onClick: logOut }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        "append-icon": "mdi-logout",
                        style: {"width":"100%"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(GlobalService).translator().trans("general.menu.log_out")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar_title, null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Be-Housing Guest App")
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})