import esquire from "@/types/clients/esquire";
import PutResponse from "@/types/clients/esquire/responses/put-response";
import PostContractNotePayload from "@/types/clients/esquire/payloads/post-contract-note-payload";
import PostContractNoteRequest
  from "@/types/clients/esquire/requests/general/post-contract-note-request";

class ContractApi {

  async postContractNote(contractId: string, noteType: string, noteDescription:null|string = null): Promise<PutResponse> {
    const payload = new PostContractNotePayload(contractId, noteType, noteDescription);
    const request = new PostContractNoteRequest(esquire);
    return await request.send(payload);

  }
}

export default new ContractApi();
