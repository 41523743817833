export default {
  home: {
    reservation_filters: "Reserveringsfilters",
    ongoing_reservations: "Lopende reserveringen",
    future_reservations: "Toekomstige reserveringen",
    open_balance: "Open saldo",
    past_reservations: "Afgelopen reserveringen",
    reference_number: "Referentienummer",
    check_in: "Check-in",
    check_out: "Check-out",
    nights: "Nachten",
    to_pay_today: "Vandaag te betalen",
    cancelled: "GEANNULEERD",
    pay_my_reservation: "BETAAL MIJN RESERVERING",
    extend_my_reservation: "VERLENG MIJN RESERVERING",
    rooms: "Kamers",
    contract_id: "Contract ID",
    arrival: "Aankomst",
    departure: "Vertrek",
    guests: "Gasten",
    unit_type: "Kamertype",
    main_guest: "Hoofdgast",
    fetching_reservations: "Reserveringen ophalen",
    offer_awaiting_reply: "offerte in afwachting van antwoord",
    offers_awaiting_reply: "offertes in afwachting van antwoord"

  },
  extend: {
    find_availabilities: "Zoek beschikbaarheden",
    select_contracts_extend: "Selecteer contracten om te verlengen",
    select_all: "Selecteer alles",
    deselect_all: "Deselecteer alles",
    id: "ID",
    stay: "Verblijf",
    unit: "Kamer",
    guest: "Gast",
    pick_new_departure: "Kies een nieuwe vertrekdatum",
    extend_for_price: "Verleng uw verblijf in deze kamer voor ",
    extend_for: "Verleng voor ",
    days: "dagen",
    pick_options: "Kies uw opties",
    previous_contract: "Vorig contract",
    next_contract: "Volgend contract",
    reservation_overview: "Overzicht van de reservering",
    total_balance: "Totaal saldo",
    confirm_extension: "Ik bevestig mijn verlenging door te klikken op doorgaan naar betaling",
    proceed_to_payment: "Doorgaan naar betaling",
    go_to_overview: "Ga naar overzicht",
    previous_balance: "Vorig saldo",
    balance_after_extension: "Saldo na verlenging"
  },
  offer: {
    offer_summary: "Overzicht offerte",
    prices_calculated_30_days: "De onderstaande prijzen zijn berekend voor de eerste 30 dagen",
    description: "Beschrijving",
    price: "Prijs",
    book_my_offer: "Boek mijn verblijf",
    total: "Totaal",
    total_first_month: "Totaal eerste maand",
    from: "Van",
    month: "maand",
    accept: "Accepteren",
    decline: "Afwijzen",
    action_required: "Actie vereist",
    one_time_costs: "Eenmalige kosten",
    offer_not_booked_yet: "Offerte nog niet geboekt.",
    enter_details: "Voer details in om de offerte in te boeken.",
    complete_guest_details: "Gelieve alle gasteninformatie en kamer voorkeuren in te vullen voordat u uw verblijf boekt.",
    guest_for: "Gast voor",
    options_for: "Opties voor",
    confirm_options_flat: "Bevestig opties voor deze flat",
    loading_options: "Opties laden",
    previous_flat: "Vorige Flat",
    next_flat: "Volgende Flat",
    cannot_proceed_max_flats: "U heeft het maximum aantal appartementen bereikt.",
    cannot_proceed_guest_info_invalid: "Vul alle gastinformatie in voordat u verdergaat.",
    cannot_proceed_options_not_confirmed: "Bevestig uw geselecteerde opties voordat u verdergaat.",
    apartment: "Apartement",
    persons: "personen"

  }

  ,
  error: {
    no_availabilities: "Geen beschikbaarheid gevonden voor deze datum. Selecteer een andere datum of neem contact op met ons sales team.",
    extend_success: "Reservering succesvol verlengd.",
    extend_failed: "Kan de reservering niet verlengen. Gelieve contact op te nemen met ons sales team."
  }
}
